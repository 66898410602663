import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { WpconnectService } from './wpconnect.service';
import { MessageService } from './message.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { User } from '../classes/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userObserver = new BehaviorSubject(JSON.parse(localStorage.getItem('auctionMongersUser'))); //to pass user to menu component

  constructor(
    public router: Router,
    public toastController: ToastController,
    public wpconnect: WpconnectService,
    private message: MessageService,
  ) { }

  async signIn(username,password){
    const user = new User();

    const loginResult = await this.wpconnect.getJWT(username,password).toPromise() as any;

    if(loginResult.success){

      user.user = username;
      user.jwt = loginResult.data.jwt;
      // unfortunatly can't get roles until we validate the JWT - plugin does not send on inital login.
      const validateResult = await this.wpconnect.validateJWT(user.jwt) as any;

      if(validateResult.success){
        user.id = parseInt(validateResult.data.user.ID, 10);
        user.roles = validateResult.data.roles;
        localStorage.setItem('auctionMongersUser', JSON.stringify(user));
        this.userObserver.next(user);//to pass user to menu component
        this.router.navigate(['home']);

      } else {
        this.presentToast('Log In Error');
      }

    } else {
      this.presentToast('Incorrect Username or Password');
    }
  }

  /*
  signIn(username,password){
    this.wpconnect.getJWT(username,password).toPromise().then(result => {
      const theResult: any = result;//don't know why I need this
      console.log(theResult);
      if(theResult.success){
        const user = {
          user: username,
          jwt: theResult.data.jwt
        };
        localStorage.setItem('auctionMongersUser', JSON.stringify(user));
        this.userObserver.next(user);//to pass user to menu component
        this.router.navigate(['home']);
      } else {
        this.presentToast('Incorrect Username or App Password');
      }
    }).catch(err =>{
      this.presentToast('Incorrect Username or App Password');
    });
  }
  */


  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('auctionMongersUser')) as User;
    return (user !== null ) ? true : false;
  }

  getUser(): User{
    return JSON.parse(localStorage.getItem('auctionMongersUser')) as User;
  }

  subscribeToUser(){

  }

  signOut(){
    const user = JSON.parse(localStorage.getItem('auctionMongersUser'));
    if (user && user.jwt){
      this.wpconnect.removeJWT(user.jwt).toPromise().then(result =>{
        console.log(result);
        localStorage.removeItem('auctionMongersUser');
      }).catch(error =>{
        console.log(error);
        localStorage.removeItem('auctionMongersUser');
      });
    }

    this.userObserver.next(null);//to pass user to menu component
    this.router.navigate(['login']);
    this.presentToast('You Are Signed Out');

  }

  async validate(){ //this function can be run before modifying the database - it returns nothing but will log out user if token is invalid
    const user = JSON.parse(localStorage.getItem('auctionMongersUser')) as User;
    if (user && user.jwt){

      const validateResult = await this.wpconnect.validateJWT(user.jwt);

      if (validateResult.success){
        //do nothing
      } else {
        this.presentToast('Session Expired. Please log in again.');
        this.signOut();
      }
    }

  }

  async changePassword(newPassword: string){
    const user = JSON.parse(localStorage.getItem('auctionMongersUser')) as User;
    if(user && user.jwt){
        const validate = await this.wpconnect.validateJWT(user.jwt);
        console.log(validate);
        if(validate && validate.data.user.user_email){
          const change = await this.wpconnect.changePassword(user.jwt,validate.data.user.user_email,newPassword) as any;
          if(change.success){
            this.presentToast('Password changed successfully');
          } else {
            this.presentToast('Error changing Password');
          }
        }
    }
  }

  async presentToast(content) {
    const toast = await this.toastController.create({
      message: content,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }

}
