import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product } from '../classes/product';
import { Search } from '../classes/search';
import { Folder } from '../classes/folder';

@Injectable({
  providedIn: 'root'
})
export class WpconnectService {

    private rootURL = 'https://wordpress-704875-2333760.cloudwaysapps.com'; //auction mongers server
    //private rootURL = 'https://testbed.local';

  private loading: any; // the loading indicator
  constructor(private http: HttpClient, private loader: LoadingController) { }

  // mazen.nuwwareh@gmail.com - x7K3e5ZMNcl




  /* ------------------------------------------------------------------------------------------------- */
  // JWT
  /* ------------------------------------------------------------------------------------------------- */

  getJWT(user,pass){
    const siteURL = this.rootURL+'/wp-json/simple-jwt-login/v1/auth';
    const headers = {
      "Content-type": 'application/x-www-form-urlencoded' // eslint-disable-line
    };
    const data = {
      'username': user,// eslint-disable-line
      'password': pass // eslint-disable-line
    };

    return this.http.post(siteURL,data,{headers});
  }

  //curl -X POST -d 'JWT=' http://localhost/wp/wp-json/simple-jwt-login/v1/auth/revoke
  removeJWT(jwt){

    const siteURL = this.rootURL+'/wp-json/simple-jwt-login/v1/auth/revoke';
    const headers = {
      "Content-type": 'application/x-www-form-urlencoded' // eslint-disable-line
    };
    const data = {
      'JWT': jwt// eslint-disable-line
    };
    return this.http.post(siteURL,data,{headers});

  }

  //curl -X GET -d 'JWT=' http://localhost/wp/wp-json/simple-jwt-login/v1/auth/validate
  async validateJWT(jwt): Promise<any>{

    const siteURL = this.rootURL+'/wp-json/simple-jwt-login/v1/auth/validate';

    const headers = {
      'Authorization': 'Bearer '+ jwt // eslint-disable-line
    };

    return this.http.get(siteURL,{headers}).toPromise();
  }

  async changePassword(jwt: string, userEmail: string, newPass: string){

    const siteURL = this.rootURL+'/wp-json/simple-jwt-login/v1/user/reset_password';
    const data = {
      email: userEmail,
      new_password: newPass// eslint-disable-line
    };

    const headers = {
      'Authorization': 'Bearer '+ jwt // eslint-disable-line
    };

    return this.http.put(siteURL,data,{headers}).toPromise();
  }
  /* ------------------------------------------------------------------------------------------------- */
  // MANIFESTS
  /* ------------------------------------------------------------------------------------------------- */
  async uploadManifest(postTitle,manifestData): Promise<any>{

    const user = JSON.parse(localStorage.getItem('auctionMongersUser'));
    const token = user.jwt;
    const tokenCheck = await this.validateJWT(token);

    if (tokenCheck && tokenCheck.success){
      console.log('SUCCESS');
      const siteURL = this.rootURL+'/wp-json/auctionmongers/v1/manifests/new'; //Your API endpoint

      const data = { //The data you want to send with the post request
        title: postTitle,
        manifestData: JSON.stringify(manifestData)
      };

      const headers = { //the request will fail without both of these headers
        "Content-type": 'application/json; charset=UTF-8', // eslint-disable-line
        'Authorization': 'Bearer '+ token // eslint-disable-line
      };

      return this.http.post(siteURL,data,{headers});

    } else {
      return Promise.reject('Failed Token Check');
    }

  }

  getAllManifests(){
    const siteURL = this.rootURL+'/wp-json/auctionmongers/v1/manifests'; //Your API endpoint
    return this.http.get(siteURL);
  }

  getSpecificManifest(title){
    const siteURL = this.rootURL+'/wp-json/auctionmongers/v1/manifests?title='+title; //Your API endpoint
    return this.http.get(siteURL);
  }


  /* ------------------------------------------------------------------------------------------------- */
  // FOLDERS
  /* ------------------------------------------------------------------------------------------------- */

  getAllFolders(){
    const siteURL = this.rootURL+'/wp-json/auctionmongers/v1/folders';
    return this.http.get(siteURL).toPromise() as Promise<Folder[]>;
  }

  async createFolder(folder: Folder){

    const user = JSON.parse(localStorage.getItem('auctionMongersUser'));
    const token = user.jwt;

    const tokenCheck = await this.validateJWT(token);

    if (tokenCheck){
      const siteURL = this.rootURL+'/wp-json/auctionmongers/v1/folders/new';

      const headers = {
        'Authorization': 'Bearer '+ token // eslint-disable-line
      };

      return this.http.post(siteURL,JSON.parse(JSON.stringify(folder)),{headers}).toPromise() as any;
    } else {

      return false;
    }
  }

  async editFolderParent(folderid: number, parentid: number){
    const user = JSON.parse(localStorage.getItem('auctionMongersUser'));
    const token = user.jwt;

    const tokenCheck = await this.validateJWT(token);

    if (tokenCheck){
      const siteURL = this.rootURL+'/wp-json/auctionmongers/v1/folders/edit';

      const headers = {
        'Authorization': 'Bearer '+ token // eslint-disable-line
      };

      return this.http.post(siteURL,{folderID: folderid, parentID: parentid},{headers}).toPromise() as any;
    } else {

      return false;
    }
  }


  /* ------------------------------------------------------------------------------------------------- */
  // PRODUCTS
  /* ------------------------------------------------------------------------------------------------- */

  async addToInventory(product: Product){ //TODO

    const user = JSON.parse(localStorage.getItem('auctionMongersUser'));
    const token = user.jwt;

    const tokenCheck = await this.validateJWT(token);

    if (tokenCheck){
      const siteURL = this.rootURL+'/wp-json/auctionmongers/v1/products/new';
      const headers = { //the request will fail without both of these headers
        "Content-type": 'application/json; charset=UTF-8', // eslint-disable-line
        //'Authorization': 'Basic '+ token // eslint-disable-line
        'Authorization': 'Bearer '+ token // eslint-disable-line
      };

      return this.http.post(siteURL,JSON.parse(JSON.stringify(product)),{headers}).toPromise();

    } else {

      return false;

    }

  }

  getAllProducts(){
    const siteURL = this.rootURL+'/wp-json/auctionmongers/v1/products';
    return this.http.get(siteURL);
  }

  getAllProductsSearch(search: Search): Observable<any>{
    const siteURL = this.rootURL+'/wp-json/auctionmongers/v1/products?offset='+search.offset+
    '&limit='+search.limit+
    '&direction='+search.direction+
    '&field='+search.field+
    '&keyword='+search.keyword+
    '&folder='+search.folder+
    '&userid='+search.userID;

    return this.http.get(siteURL);
  }

  async moveProductsToFolder(_productIDs: number[], _folderID: number){

    const user = JSON.parse(localStorage.getItem('auctionMongersUser'));
    const token = user.jwt;

    const tokenCheck = await this.validateJWT(token);

    if (tokenCheck){
      const siteURL = this.rootURL+'/wp-json/auctionmongers/v1/products/folder_update';

      const headers = { //the request will fail without both of these headers
        'Authorization': 'Bearer '+ token // eslint-disable-line
      };

      const data = {
        productIDs: _productIDs,
        folderID: _folderID
      };

      return this.http.post(siteURL,JSON.parse(JSON.stringify(data)),{headers}).toPromise() as any;
    } else {

      return false;
    }
  }

  async updateProduct(product: Product){
    const user = JSON.parse(localStorage.getItem('auctionMongersUser'));
    const token = user.jwt;

    const tokenCheck = await this.validateJWT(token);

    if (tokenCheck){
      const siteURL = this.rootURL+'/wp-json/auctionmongers/v1/products/edit';

      const headers = { //the request will fail without both of these headers
        'Authorization': 'Bearer '+ token // eslint-disable-line
      };

      return this.http.post(siteURL,JSON.parse(JSON.stringify(product)),{headers}).toPromise() as any;

    } else {

      return false;

    }
  }


  /* ------------------------------------------------------------------------------------------------- */
  // LABELS
  /* ------------------------------------------------------------------------------------------------- */

  getLabelsWithPrefix(prefix){
    const siteURL = this.rootURL+'/wp-json/auctionmongers/v1/labels?prefix='+prefix;
    return this.http.get(siteURL).toPromise();
  }

  async createLabelWithPrefix(prefix){
    const user = JSON.parse(localStorage.getItem('auctionMongersUser'));
    const token = user.jwt;
    console.log(token);

    const tokenCheck = await this.validateJWT(token);

    if (tokenCheck){
      const siteURL = this.rootURL+'/wp-json/auctionmongers/v1/labels/new';

      const headers = {
        'Authorization': 'Bearer '+ token // eslint-disable-line
      };

      const data = {
        labelPrefix: prefix
      };

      return this.http.post(siteURL,JSON.parse(JSON.stringify(data)),{headers}).toPromise();

    } else {

      return false;

    }

  }


  /* ------------------------------------------------------------------------------------------------- */
  // IMAGES
  /* ------------------------------------------------------------------------------------------------- */

  async dataURLtoFile(dataurl: string, filename: string) { //converts a dataURL to a file object
    //https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
      const arr = dataurl.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {type:mime});
  }

  async uploadImageFromDataURL(imageName: string, dataURL: string, type: string){
    // NEED THIS IN wp-config -> define('ALLOW_UNFILTERED_UPLOADS', true);
    const siteURL = this.rootURL+'/wp-json/wp/v2/media';

    const user = JSON.parse(localStorage.getItem('auctionMongersUser'));
    const token = user.jwt;

    //const token = btoa('admin:'+ 'eI1r BOFq GiGQ knrh LFin x9Ui'); //a base64 encoded string of the username + app password

    const currentTime = new Date().getTime();

    const fileToUpload = await this.dataURLtoFile(dataURL,imageName+'.'+type);

    const formData = new FormData();
    formData.append('file', fileToUpload);
    formData.append('title', imageName);

    const headers = {
      'Authorization': 'Bearer '+ token // eslint-disable-line
      //'Authorization': 'Basic '+ token // eslint-disable-line

    };

    return this.http.post(siteURL,formData,{headers}).toPromise();

  }

  /* ------------------------------------------------------------------------------------------------- */
  // OTHER
  /* ------------------------------------------------------------------------------------------------- */

  async presentLoading() {
    this.loading = await this.loader.create({
      cssClass: 'my-custom-class',
      message: 'uploading...',
    });
    await this.loading.present();
  }

  dismissLoading(){
    this.loading.dismiss();
  }


}

