import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Home', url: '/home', icon: 'home' },
    { title: 'Add a Product', url: '/product', icon: 'add' },
    { title: 'Inventory', url: '/inventory', icon: 'albums' },
    { title: 'Upload Order', url: '/upload', icon: 'cloud-upload' },
    { title: 'Create Labels', url: '/labels', icon: 'barcode' },
    { title: 'Login / Logout', url: '/login', icon: 'person' },

  ];

  public user;

  constructor(private auth: AuthService) {
    this.auth.userObserver.subscribe(result =>{
      this.user = result;
    });
  }

}
