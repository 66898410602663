import { Injectable } from '@angular/core';
import { ToastController, LoadingController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class MessageService {

  public loader;

  constructor(public toastController: ToastController, public loadingController: LoadingController) { }

  async display(toastMessage: string){
    const toast = await this.toastController.create({
      message: toastMessage,
      duration: 2000,
      position: 'top'
    });
    toast.present();
  }

  async presentLoading(withMessage: string) {
    this.loader = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: withMessage,
      duration: 20000
    });
    await this.loader.present();
  }

  dismissLoading(){
    if (this.loader){
      this.loader.dismiss();
    }
  }


}
