export class User {

  id: number;
  user: string;
  jwt: string;
  email: string;
  roles: string[];

  constructor(){}
}

